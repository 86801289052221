// ScrollItems.js
import React from 'react';
import '../global.css'; 

const ScrollItems = ({  title, urls}) => {
    return (
    <div className="scroll-items bg-dark vp-container pt-4">
  {/* Your scroll items/content go here */}  
  <div class="row gap-5">
    <div class="col-md">
      <span class='custom-large-heading light-col'>{title}</span>
    </div>
    <div class="col-md">
      <span class='montser-sml-LightItalic light-col ft-section-heading'>Your gateway to news and innovative theories in education, all woven into the fabric of our learning community's experiences and insights</span>
      <a href="/blogdashboard" className="nav-link montser-mdm-2 text-yellow d-flex align-center justify-content-start mt-5 mb-5" rel="noopener noreferrer">
        <span class="text-decorator-underline">View more {title} posts</span>
      </a>
    </div>
  </div>
  <div className="row text-yellow pt-2 gx-5 img-spacing">
    <div className="col-md-6 col-lg-4 my-4">
      <div className="card border-0 text-yellow bg-transparent">
      <a href="/blog4" className="text-decoration-none" >
        <div className='d-flex flex-column headings'>
        <span className="scroll-item mb-1 text-yellow scroll-text montser-mdm-2 common-textshadow">Shadows Over the Academy</span>
        <span className="scroll-item mb-1  text-yellow montser-sml-LightItalic">Unmasking Double Standards</span>
        </div>
        <img src='images/Blog_Shadows Over the Academy_Landing Page 1.png' alt="image not found" className="img-fluid rounded-top rounded-0 common-boxshadow"  ></img>
        </a>
      </div>
    </div>
    
    <div className="col-md-6 col-lg-4 my-4">
      <div className="card border-0 text-yellow bg-transparent">
      <a href="/blog3" className="text-decoration-none" >
      <div className='d-flex flex-column headings'>
      <span className="scroll-item mb-1 text-yellow scroll-text common-textshadow montser-mdm-2">
      Effective Repetition
      </span>
        <span className="scroll-item mb-1  text-yellow montser-sml-LightItalic">The Timeless Tool Challenging Modern Education Myths</span>
        </div>
        <img src='images/Blog_Effective Repetition_Landing Page 1.png' alt="image not found" className="img-fluid rounded-top rounded-0 common-boxshadow"></img>
    </a>
      </div>
    </div>
    <div className="col-md-6 col-lg-4 my-4">
      <div className="card border-0 text-yellow bg-transparent">
          <a href="/blog7 " className="text-decoration-none" >
              <div className='d-flex flex-column headings'>
              <span className="scroll-item mb-1 scroll-text text-yellow common-textshadow  montser-mdm-2 ">The Neuroscience of Focus in Education</span>
                  <span className="scroll-item mb-1 text-yellow montser-sml-LightItalic">Unveiling a New Paradigm for Learning</span>
              </div>
                  <img src='images/Blog_Neuroscience of Focus_Landing Page 1.png' alt="image not found" className="img-fluid rounded-top rounded-0 common-boxshadow"  ></img>
          </a>
      </div>
  </div>
    <div className="col-12">
      <hr className='mt-8rem'></hr>
    </div>
  </div>
</div>
  );
};

export default ScrollItems;