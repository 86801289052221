
import React from 'react';
import { useEffect } from 'react';
import '../global.css';
import '../Fonts/fontello-icon.css';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import MyPopupbutton from '../react-calendly/MyPopupbutton';

const Neurofeedback = () => {
  useEffect(() => {
    const elements = document.getElementsByClassName("text-yellow");

    for (let i = 0; i < elements.length; i++) {
      elements[i].classList.add(".text-dark1");
    }
    const section = document.getElementsByClassName("mainSectionDiv")[0];
    section.classList.add("neurofeedbackSectionActive");
  }, []);

  return (
    // <>

    <div className=''>
      {/* <div style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'bg-dark' }} className='bg-body'> */}
      {/* <> */}
      <Navbar />
      <div style={{ height: '100%', paddingTop: '100px' }} className='bg-body-neuro'>
      <div className="container-fluid vp-container gap-3 gap-md-5 no-flex">
          <section>
            <h4 className='mb-3 custom-large-heading section-margin'>Insightful Analytics:</h4>
            <hr className='mt-5'></hr>
          </section>
          
          <section className='vp-section'>
              <div className='section-contaner'>

                <div className='row'>
                  <div className='col-12 col-md-6 flex-column align-items-start inner-container gap-5 '>

                  <div className='mb-0 w-100'>
                    <div className='d-flex flex-row align-items-center justify-content-between gap-3'>
                      <div className='job-width lh-lg '>
                        <h5 className='ommon-textshadow  montser-mdm-2 ft-4vh '>FUNCTION:</h5>
                        <span className='montser-mdm-2 common-textshadow ft-2-5vh' >Comprehensive Academic and Behavioral Metrics</span>
                        <p className='montser-sml-LightItalic common-textshadow ft-2-3vh'>Insightful Analytics goes beyond traditional academic evaluations to include behavioral metrics, such as student focus and attention. This broader spectrum of data provides a richer, more nuanced understanding of each learner's educational experience and mood regulation.</p>
                      </div>
                      <div><i className='demo-icon icon-checkiconsolid' /></div>
                    </div>
                  </div>

                  <div className='mb-0 mb-0 w-100'>
                    <div className='d-flex flex-row align-items-center justify-content-between gap-3'>
                      <div className='job-width lh-lg'>
                        <h6 className='ommon-textshadow  montser-mdm-2 ft-4vh '>FUNCTION:</h6>
                        <span className='montser-mdm-2 common-textshadow ft-2-5vh'>Enhanced Understanding of Learning Environments</span>
                        <p className='montser-sml-LightItalic common-textshadow ft-2-3vh'>By capturing detailed insights into both academic performance and behavioral patterns, Insightful Analytics offers educators a clearer picture of the learning environment's effectiveness. This dual focus helps identify areas where interventions can improve both knowledge acquisition and emotional well-being.</p>
                      </div>
                      <div><i className='demo-icon icon-checkiconsolid' /></div>
                    </div>
                  </div>

                  <div className='mb-0 mb-0 w-100'>
                    <div className='d-flex flex-row align-items-center justify-content-between gap-3'>
                      <div className='job-width lh-lg'>
                        <h5 className='ommon-textshadow  montser-mdm-2 ft-4vh '>FUNCTION:</h5>
                        <span className='montser-mdm-2 common-textshadow ft-2-5vh'>Data-Driven Strategies for Improved Outcomes</span>
                        <p className='montser-sml-LightItalic common-textshadow ft-2-3vh'>Leveraging advanced analytics, this feature enables the development of targeted strategies that address both cognitive and emotional aspects of learning. The integration of behavioral data enriches the approach to education, fostering environments that support holistic student growth.</p>
                      </div>
                      <div><i className='demo-icon icon-checkiconsolid' /></div>
                    </div>
                  </div>
                </div>
                <div className='col-md-5 col-12 mt-3 mt-md-0 d-flex align-items-center'>
                  <div className='w-100 maxw-450 ms-auto d-flex mt-5 image-container'>
                    <img src='/images/Neuro Biofeedback.png' alt="image not found" className="w-100 object-fit-cover img-fluid rounded-top rounded-0 common-boxshadow img-contain section-img ms-auto" />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>


          <section className='vp-section'>
            <div className='justify-content-center'>
              <div className=' d-flex flex-column align-items-start text-left gap-5'>
                <p className='montser-mdm-2 vp-container common-textshadow mb-3 text-center lh-lg ft-3vh'>
                Elevate Educational Excellence with Insightful Analytics 
                </p>
                <div className="width-100 ">
                  <img src='/images/Insightful Analytics Wide.png' alt="image not found" className="rounded-top rounded-0 common-boxshadow student-wide-img" />
                </div>
              </div>
            </div>
          </section>

          
          <div className="container-fluid vp-container gap-3 gap-md-5 no-flex">

          <section className='vp-section'>
            <div className='row align-items-center'>
              <div className='col-sm-5'>
                <div className='align-items-center inner-container gap-5'>
                  <div className=' justify'>
                    <p className='montser-sml-LightItalic  common-textshadow text-start lh-lg ft-3vh'>
                    Provides a dual analysis of academic performance and behavioral indicators, offering a comprehensive view of student progress.
                                      </p>                    
                  </div>
                </div>
              </div>
              <div className='col-sm-6'>
                  <div className='w-100 maxw-450 ms-auto d-flex mt-5'>
                    <img src='/images/Brain Training 1.png' alt="image not found" className="w-100 object-fit-cover img-fluid rounded-top rounded-0 common-boxshadow img-contain section-img ms-auto" />
                  </div>
              </div>
            </div>
          </section>

          <section className='vp-section'>
            <div className='row align-items-center'>
            <div className='col-sm-5 d-block d-sm-none'>
                <div className='align-items-center inner-container gap-5'>
                <div className="mt-3 mt-sm-0"><p className="montser-sml-LightItalic lh-lg ft-3vh">
                Enhances the capacity to tailor learning experiences to individual needs, including mood regulation and focus.                </p>
                  </div>
                </div>
              </div>
            <div className='col-sm-7'>
                  <div className='w-100 maxw-450 me-auto d-flex mt-5'>
                    <img src='/images/Brain Training 2.png' alt="image not found" className="w-100 object-fit-cover img-fluid rounded-top rounded-0 common-boxshadow img-contain section-img ms-auto" />

                  </div>
              </div>
              <div className='col-sm-5 d-none d-sm-block'>
                <div className='align-items-center inner-container gap-5'>
                <div className="mt-3 mt-sm-0">
                  <p className="montser-sml-LightItalic lh-lg ft-3vh">
                  Enhances the capacity to tailor learning experiences to individual needs, including mood regulation and focus.                </p>
                    </div>
                </div>
              </div>
            </div>
          </section>

          <section className='vp-section'>
            <div className='row align-items-center'>
              <div className='col-sm-5'>
                <div className='align-items-center inner-container gap-5'>
                  <div className=' justify'>
                    <p className='montser-sml-LightItalic  common-textshadow text-start lh-lg ft-3vh'>
                    Employs data-driven insights to optimize learning environments for both academic success and emotional well-being.                  </p>                    
                  </div>
                </div>
              </div>
              <div className='col-sm-6'>
                  <div className='w-100 maxw-450 ms-auto d-flex mt-5'>
                    <img src='/images/Brain Training 3.png' alt="image not found" className="w-100 object-fit-cover img-fluid rounded-top rounded-0 common-boxshadow img-contain section-img ms-auto" />
                  </div>
              </div>
            </div>
          </section>


          <section className='vp-section'>
            <div className='row align-items-center'>
            <div className='col-sm-5 d-block d-sm-none'>
                <div className='align-items-center inner-container gap-5'>
                <div className="mt-3 mt-sm-0"><p className="montser-sml-LightItalic lh-lg ft-3vh">
                Supports educators and administrators in making informed decisions that promote holistic development.</p>
                  </div>
                </div>
              </div>
            <div className='col-sm-7'>
                  <div className='w-100 maxw-450 me-auto d-flex mt-5'>
                    <img src='/images/learning image 5.webp' alt="image not found" className="w-100 object-fit-cover img-fluid rounded-top rounded-0 common-boxshadow img-contain section-img ms-auto" />

                  </div>
              </div>
              <div className='col-sm-5 d-none d-sm-block'>
                <div className='align-items-center inner-container gap-5'>
                <div className="mt-3 mt-sm-0">
                  <p className="montser-sml-LightItalic lh-lg ft-3vh">
                  Supports educators and administrators in making informed decisions that promote holistic development.</p>
                    </div>
                </div>
              </div>
            </div>
          </section>

          <section className='vp-section'>
            <div className='row align-items-center'>
              <div className='col-sm-5'>
                <div className='align-items-center inner-container gap-5'>
                  <div className=' justify'>
                    <p className='montser-sml-LightItalic  common-textshadow text-start lh-lg ft-3vh'>
                    Strengthens the connection between learning outcomes and behavioral health, paving the way for more effective interventions.                  </p>                    
                  </div>
                </div>
              </div>
              <div className='col-sm-6'>
                  <div className='w-100 maxw-450 ms-auto d-flex mt-5'>
                    <img src='/images/learning image 1.webp' alt="image not found" className="w-100 object-fit-cover img-fluid rounded-top rounded-0 common-boxshadow img-contain section-img ms-auto" />
                  </div>
              </div>
            </div>
          </section>
          <section className='vp-section'>
            <div className='row align-items-center'>
            <div className='col-sm-7'>
                  <div className='w-100 maxw-450 me-auto d-flex mt-5'>                  
              <img src='/images/learning image 4.webp' alt="image not found" className="w-100 object-fit-cover img-fluid rounded-top rounded-0 common-boxshadow img-contain section-img ms-auto" />

                  </div>
              </div>
              <div className='col-sm-5 d-none d-sm-block'>
                <div className='align-items-center inner-container gap-5'>
                <div className="mt-3 mt-sm-0">
                  <p className="montser-sml-LightItalic lh-lg ft-3vh">
                  Click 'Meet Us' to discover how Insightful Analytics can transform your educational approach, ensuring a balanced focus on both academic and behavioral growth.</p>
                    </div>
                </div>
              </div>
              <div class="row">
<div class="col-sm-7">
</div>
<div class="col-sm-3">
<div class="d-flex align-items-center mt-4 mt-sm-0 justify-content-end footer">
<div class="App">
<button class="outline-lightgreen py-0 montser-mdm-2 rounded-top rounded-0 btn btn-outline-light meet-us ft-2-5vh p-4">Meet us</button>
</div></div></div></div>
            </div>
          </section>

         
        </div>
        <Footer />
      </div>
      {/* </> */}
    </div>
    // </div>
    // </>


  );
};

export default Neurofeedback;

