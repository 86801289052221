
import React from 'react';
import { useEffect } from 'react';
import '../global.css';
import '../Fonts/fontello-icon.css';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import MyPopupbutton from '../react-calendly/MyPopupbutton';

const EnhancedAi = () => {
  useEffect(() => {
    const elements = document.getElementsByClassName("text-yellow");

    for (let i = 0; i < elements.length; i++) {
      elements[i].classList.add(".text-dark1");
    }
    const section = document.getElementsByClassName("mainSectionDiv")[0];
    section.classList.add("enhancedAiSectionActive");
  }, []);

  return (
    // <>

    <div className=''>
      {/* <div style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'bg-dark' }} className='bg-body'> */}
      {/* <> */}
      <Navbar />
      <div style={{ height: '100%', paddingTop: '100px' }} className='bg-body-enhanced '>
      <div className="container-fluid vp-container gap-3 gap-md-5 no-flex">
          <section>
            <h4 className='mb-3 custom-large-heading section-margin'>Adaptive Measurement</h4>
            <hr className='mt-5'></hr>
          </section>
          
          <section className='vp-section'>
              <div className='section-contaner'>

                <div className='row'>
                  <div className='col-12 col-md-6 flex-column align-items-start inner-container gap-5 '>

                    <div className='mb-0 w-100'>
                      <div className='d-flex flex-row align-items-center justify-content-between gap-3'>
                        <div className='job-width lh-lg '>
                          <h5 className='ommon-textshadow  montser-mdm-2 ft-4vh '>FUNCTION:</h5>
                        <span className='montser-mdm-2 common-textshadow ft-2-5vh' >Tailored Learning Insights</span>
                        <p className='montser-sml-LightItalic common-textshadow ft-2-3vh'>Adaptive Measurement revolutionizes the approach to assessing student performance by dynamically adjusting to each learner's ability level. This feature ensures assessments are both challenging and accessible, providing a balanced measure of student progress and potential.</p>
                      </div>
                      <div><i className='demo-icon icon-checkiconsolid' /></div>
                    </div>
                  </div>

                  <div className='mb-0 mb-0 w-100'>
                    <div className='d-flex flex-row align-items-center justify-content-between gap-3'>
                      <div className='job-width lh-lg'>
                        <h6 className='ommon-textshadow  montser-mdm-2 ft-4vh '>FUNCTION:</h6>
                        <span className='montser-mdm-2 common-textshadow ft-2-5vh'>Personalized Assessment Pathways</span>
                        <p className='montser-sml-LightItalic common-textshadow ft-2-3vh'>By intelligently adapting to the student's responses, Adaptive Measurement creates a personalized assessment experience. This customization leads to more accurate and meaningful insights into a student's capabilities, enabling targeted support and intervention.</p>
                      </div>
                      <div><i className='demo-icon icon-checkiconsolid' /></div>
                    </div>
                  </div>

                  <div className='mb-0 mb-0 w-100'>
                    <div className='d-flex flex-row align-items-center justify-content-between gap-3'>
                      <div className='job-width lh-lg'>
                        <h5 className='ommon-textshadow  montser-mdm-2 ft-4vh '>FUNCTION:</h5>
                        <span className='montser-mdm-2 common-textshadow ft-2-5vh'>Optimized Learning Trajectories</span>
                        <p className='montser-sml-LightItalic common-textshadow ft-2-3vh'>The dynamic nature of Adaptive Measurement facilitates the optimization of learning trajectories. It ensures that assessments are directly aligned with the student's current level of understanding, promoting continuous and effective learning progress.</p>
                      </div>
                      <div><i className='demo-icon icon-checkiconsolid' /></div>
                    </div>
                  </div>
                </div>
                <div className='col-md-5 col-12 mt-3 mt-md-0 d-flex align-items-center'>
                  <div className='w-100 maxw-450 ms-auto d-flex mt-5 image-container'>
                      <img src='/images/Enhanced Learning AI.png' alt="image not found" className="w-100 object-fit-cover img-fluid rounded-top rounded-0 common-boxshadow img-contain section-img ms-auto" />
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>

          <section className='vp-section'>
            <div className='justify-content-center'>
              <div className=' d-flex flex-column align-items-start text-left gap-5'>
                <p className='montser-mdm-2 vp-container common-textshadow mb-3 text-center lh-lg ft-3vh'>
                Elevate Assessment with Precision and Personalization 
                </p>
                <div className="width-100 ">
                  <img src='/images/Adaptive Measurement Wide.png' alt="image not found" className="rounded-top rounded-0 common-boxshadow student-wide-img" />
                </div>
              </div>
            </div>
          </section>

          
          <div className="container-fluid vp-container gap-3 gap-md-5 no-flex">

          <section className='vp-section'>
            <div className='row align-items-center'>
            <div className='col-sm-7 d-block d-sm-none'>
                <div className='align-items-center inner-container gap-5'>
                <div className="mt-3 mt-sm-0"><p className="montser-sml-LightItalic lh-lg ft-3vh">
                Creates a tailored assessment experience that adjusts in real-time to the student's performance, ensuring relevance and challenge.
                </p>
                  </div>
                </div>
              </div>
            <div className='col-sm-7'>
                  <div className='w-100 maxw-450 me-auto d-flex mt-5'>
                    <img src='/images/Brain Training 3.png' alt="image not found" className="w-100 object-fit-cover img-fluid rounded-top rounded-0 common-boxshadow img-contain section-img ms-auto" />

                  </div>
              </div>
              <div className='col-sm-5 d-none d-sm-block'>
                <div className='align-items-center inner-container gap-5'>
                <div className="mt-3 mt-sm-0">
                  <p className="montser-sml-LightItalic lh-lg ft-3vh">
                  Creates a tailored assessment experience that adjusts in real-time to the student's performance, ensuring relevance and challenge.                </p>
                    </div>
                </div>
              </div>
            </div>
          </section>

          <section className='vp-section'>
            <div className='row align-items-center'>
              <div className='col-sm-5'>
                <div className='align-items-center inner-container gap-5'>
                  <div className=' justify'>
                    <p className='montser-sml-LightItalic  common-textshadow text-start lh-lg ft-3vh'>
                    Enhances the accuracy of learning measurement by capturing the true breadth of a student's abilities and knowledge base.
                  </p>                    
                  </div>
                </div>
              </div>
              <div className='col-sm-6'>
                  <div className='w-100 maxw-450 ms-auto d-flex mt-5'>
                    <img src='/images/Brain Training 2.png' alt="image not found" className="w-100 object-fit-cover img-fluid rounded-top rounded-0 common-boxshadow img-contain section-img ms-auto" />
                  </div>
              </div>
            </div>
          </section>

          <section className='vp-section'>
            <div className='row align-items-center'>
            <div className='col-sm-7 d-block d-sm-none'>
                <div className='align-items-center inner-container gap-5'>
                <div className="mt-3 mt-sm-0"><p className="montser-sml-LightItalic lh-lg ft-3vh">
                Supports educators in identifying specific areas of strength and need, enabling more focused and effective instruction.
                </p>
                  </div>
                </div>
              </div>
            <div className='col-sm-7'>
                  <div className='w-100 maxw-450 me-auto d-flex mt-5'>
                    <img src='/images/Brain Training 1.png' alt="image not found" className="w-100 object-fit-cover img-fluid rounded-top rounded-0 common-boxshadow img-contain section-img ms-auto" />

                  </div>
              </div>
              <div className='col-sm-5 d-none d-sm-block'>
                <div className='align-items-center inner-container gap-5'>
                <div className="mt-3 mt-sm-0">
                  <p className="montser-sml-LightItalic lh-lg ft-3vh">
                  Supports educators in identifying specific areas of strength and need, enabling more focused and effective instruction.                </p>
                    </div>
                </div>
              </div>
            </div>
          </section>

          <section className='vp-section'>
            <div className='row align-items-center'>
              <div className='col-sm-5'>
                <div className='align-items-center inner-container gap-5'>
                  <div className=' justify'>
                    <p className='montser-sml-LightItalic  common-textshadow text-start lh-lg ft-3vh'>
                    Fosters an environment of personalized learning, where assessments contribute to an individualized learning path.
                  </p>                    
                  </div>
                </div>
              </div>
              <div className='col-sm-6'>
                  <div className='w-100 maxw-450 ms-auto d-flex mt-5'>
                    <img src='/images/learning image 1.webp' alt="image not found" className="w-100 object-fit-cover img-fluid rounded-top rounded-0 common-boxshadow img-contain section-img ms-auto" />
                  </div>
              </div>
            </div>
          </section>


          <section className='vp-section'>
            <div className='row align-items-center'>
            <div className='col-sm-7 d-block d-sm-none'>
                <div className='align-items-center inner-container gap-5'>
                <div className="mt-3 mt-sm-0"><p className="montser-sml-LightItalic lh-lg ft-3vh">
                Strengthens the feedback loop between teaching and learning, ensuring that assessment data directly informs educational strategies.
                </p>
                  </div>
                </div>
              </div>
            <div className='col-sm-7'>
                  <div className='w-100 maxw-450 me-auto d-flex mt-5'>
                    <img src='/images/learning image 2.webp' alt="image not found" className="w-100 object-fit-cover img-fluid rounded-top rounded-0 common-boxshadow img-contain section-img ms-auto" />

                  </div>
              </div>
              <div className='col-sm-5 d-none d-sm-block'>
                <div className='align-items-center inner-container gap-5'>
                <div className="mt-3 mt-sm-0">
                  <p className="montser-sml-LightItalic lh-lg ft-3vh">
                  Strengthens the feedback loop between teaching and learning, ensuring that assessment data directly informs educational strategies.                </p>
                    </div>
                </div>
              </div>
            </div>
          </section>


          
          <section className='vp-section'>
            <div className='row align-items-center'>
              <div className='col-sm-5'>
                <div className='align-items-center inner-container gap-5'>
                  <div className=' justify'>

                    <p className='montser-sml-LightItalic  common-textshadow text-start lh-lg ft-3vh'>
                    Click 'Meet Us' to discover how Learning Measurement Lab's Adaptive Measurement is setting a new standard in personalized, dynamic assessment for the modern educational landscape.
                      </p>
                  </div>
                </div>
              </div>
              <div className='col-sm-6'>
                  <div className='w-100 maxw-450 ms-auto d-flex mt-5'>
                    <img src='/images/learning image 3.webp' alt="image not found" className="w-100 object-fit-cover img-fluid rounded-top rounded-0 common-boxshadow img-contain section-img ms-auto" />
                  </div>
              </div>
            </div>
            <div className="row ">
                    <div className='col-sm-5'>
                      < div className='d-flex align-items-center mt-4 mt-sm-0 justify-content-center footer'>
                          <MyPopupbutton/>
                        </div>
                    </div>
                    <div className='col-sm-7'></div>
                  </div>
          </section>
        </div>
        <Footer />
      </div>
      {/* </> */}
    </div>
    // </div>
    // </>


  );
};

export default EnhancedAi;

