
import React from 'react';
import { useEffect } from 'react';
import '../global.css';
import '../Fonts/fontello-icon.css';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import MyPopupbutton from '../react-calendly/MyPopupbutton';

const Culture = () => {
  useEffect(() => {
    const elements = document.getElementsByClassName("text-yellow");

    for (let i = 0; i < elements.length; i++) {
      elements[i].classList.add(".text-dark1");
    }
    const section = document.getElementsByClassName("mainSectionDiv")[0];
    section.classList.add("cultureSectionActive");
  }, []);

  return (
    // <>

    <div className=''>
      {/* <div style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'bg-dark' }} className='bg-body'> */}
      {/* <> */}
      <Navbar />
      <div style={{ height: '100%', paddingTop: '100px' }} className='bg-body-culture'>
      <div className="container-fluid vp-container gap-3 gap-md-5 no-flex">
          <section>
            <h4 className='mb-3 custom-large-heading section-margin'>Real-Time Feedback</h4>
            <hr className='mt-5'></hr>
          </section>
          
          <section className='vp-section'>
              <div className='section-contaner'>

                <div className='row'>
                  <div className='col-12 col-md-6 flex-column align-items-start inner-container gap-5 '>

                    <div className='mb-0 w-100'>
                      <div className='d-flex flex-row align-items-center justify-content-between gap-3'>
                        <div className='job-width lh-lg '>
                          <h5 className='ommon-textshadow  montser-mdm-2 ft-4vh '>FUNCTION:</h5>
                          <span className='montser-mdm-2 common-textshadow ft-2-5vh' >Advanced Assessment at the Moment</span>
                          <p className='montser-sml-LightItalic common-textshadow ft-2-3vh'>Real-Time Feedback offers an unparalleled level of precision in learning assessment, capturing intricate details of student performance as they occur. This advanced methodology ensures educators and institutions access immediate, actionable insights, transcending traditional evaluation techniques.</p>
                        </div>
                        <div><i className='demo-icon icon-checkiconsolid' /></div>
                      </div>
                    </div>

                    <div className='mb-0 mb-0 w-100'>
                      <div className='d-flex flex-row align-items-center justify-content-between gap-3'>
                        <div className='job-width lh-lg'>
                          <h6 className='ommon-textshadow  montser-mdm-2 ft-4vh '>FUNCTION:</h6>
                          <span className='montser-mdm-2 common-textshadow ft-2-5vh'>Enhanced Decision-Making with Immediate Insights</span>
                          <p className='montser-sml-LightItalic common-textshadow ft-2-3vh'>By providing instant feedback, this feature significantly enhances the ability of educators to make informed, timely decisions. It represents a paradigm shift in educational assessment, allowing for the immediate adjustment of teaching strategies to better meet student needs.</p>
                        </div>
                        <div><i className='demo-icon icon-checkiconsolid' /></div>
                      </div>
                    </div>

                    <div className='mb-0 mb-0 w-100'>
                      <div className='d-flex flex-row align-items-center justify-content-between gap-3'>
                        <div className='job-width lh-lg'>
                          <h5 className='ommon-textshadow  montser-mdm-2 ft-4vh '>FUNCTION:</h5>
                          <span className='montser-mdm-2 common-textshadow ft-2-5vh'>Dynamic Educational Adaptation</span>
                          <p className='montser-sml-LightItalic common-textshadow ft-2-3vh'>The instantaneity of Real-Time Feedback facilitates a highly adaptive educational environment. Educators can tailor the learning experience in real-time, ensuring that teaching methods, materials, and pacing are optimally aligned with each student's learning journey.</p>
                        </div>
                        <div><i className='demo-icon icon-checkiconsolid' /></div>
                      </div>
                    </div>            
                  </div>
                  <div className='col-md-5 col-12 mt-3 mt-md-0 d-flex align-items-center'>
                    <div className='w-100 maxw-450 ms-auto d-flex mt-5 image-container'>
                      <img src='/images/Culture Focused 1.png' alt="image not found" className="w-100 object-fit-cover img-fluid rounded-top rounded-0 common-boxshadow img-contain section-img ms-auto" />
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>


          <section className='vp-section'>
            <div className='justify-content-center'>
              <div className=' d-flex flex-column align-items-start text-left gap-5'>
                <p className='montser-mdm-2 vp-container common-textshadow mb-3 text-center lh-lg ft-3vh'>
                Redefine Learning Assessment with Instantaneous Insights 
                </p>
                <div className="width-100 ">
                  <img src='/images/Real-Time Feedback Wide.png' alt="image not found" className="rounded-top rounded-0 common-boxshadow student-wide-img" />
                </div>
              </div>
            </div>
          </section>

          
          <div className="container-fluid vp-container gap-3 gap-md-5 no-flex">
         
          <section className='vp-section'>
            <div className='row align-items-center'>
              <div className='col-sm-5'>
                <div className='align-items-center inner-container gap-5'>
                  <div className=' justify'>
                    <p className='montser-sml-LightItalic  common-textshadow text-start lh-lg ft-3vh'>
                    Delivers immediate, comprehensive evaluations of student performance, setting a new standard in learning assessment.</p>                    
                  </div>
                </div>
              </div>
              <div className='col-sm-7'>
                  <div className='w-100 maxw-450 ms-auto d-flex mt-5'>
                    <img src='/images/Brain Training 1.png' alt="image not found" className="w-100 object-fit-cover img-fluid rounded-top rounded-0 common-boxshadow img-contain section-img me-auto" />
                  </div>
              </div>
            </div>
          </section>

          <section className='vp-section'>
            <div className='row align-items-center'>
            <div className='col-sm-5 d-block d-sm-none'>
                <div className='align-items-center inner-container gap-5'>
                <div className="mt-3 mt-sm-0"><p className="montser-sml-LightItalic lh-lg ft-3vh">
                Enables educators and institutions to act swiftly, applying data-driven adjustments to enhance the learning experience.                      </p>
                  </div>
                </div>
              </div>
            <div className='col-sm-7'>
                  <div className='w-100 maxw-450 me-auto d-flex mt-5'>
                    <img src='/images/Brain Training 3.png' alt="image not found" className="w-100 object-fit-cover img-fluid rounded-top rounded-0 common-boxshadow img-contain section-img ms-auto" />

                  </div>
              </div>
              <div className='col-sm-5 d-none d-sm-block'>
                <div className='align-items-center inner-container gap-5'>
                <div className="mt-3 mt-sm-0">
                  <p className="montser-sml-LightItalic lh-lg ft-3vh">
                  Enables educators and institutions to act swiftly, applying data-driven adjustments to enhance the learning experience.                      </p>
                    </div>
                </div>
              </div>
            </div>
          </section>


          
          <section className='vp-section'>
            <div className='row align-items-center'>
              <div className='col-sm-5'>
                <div className='align-items-center inner-container gap-5'>
                  <div className=' justify'>

                    <p className='montser-sml-LightItalic  common-textshadow text-start lh-lg ft-3vh'>
                    Promotes a more agile and responsive educational framework, capable of adapting to the unique needs of each learner.
                      </p>
                  </div>
                </div>
              </div>
              <div className='col-sm-6'>
                  <div className='w-100 maxw-450 ms-auto d-flex mt-5'>
                    <img src='/images/Brain Training 2.png' alt="image not found" className="w-100 object-fit-cover img-fluid rounded-top rounded-0 common-boxshadow img-contain section-img ms-auto" />
                  </div>
              </div>
            </div>
          </section>


          <section className='vp-section'>
            <div className='row align-items-center'>
            <div className='col-sm-5 d-block d-sm-none'>
                <div className='align-items-center inner-container gap-5'>
                <div className="mt-3 mt-sm-0"><p className="montser-sml-LightItalic lh-lg ft-3vh">
                Guarantees that feedback is both timely and pertinent, allowing for rapid interventions to support student success.</p>
                  </div>
                </div>
              </div>
            <div className='col-sm-6'>
                  <div className='w-100 maxw-450 me-auto d-flex mt-5'>
                    <img src='/images/learning image 2.webp' alt="image not found" className="w-100 object-fit-cover img-fluid rounded-top rounded-0 common-boxshadow img-contain section-img ms-auto" />

                  </div>
              </div>
              <div className='col-sm-5 d-none d-sm-block'>
                <div className='align-items-center inner-container gap-5'>
                <div className="mt-3 mt-sm-0">
                  <p className="montser-sml-LightItalic lh-lg ft-3vh">
                  Guarantees that feedback is both timely and pertinent, allowing for rapid interventions to support student success.</p>
                    </div>
                </div>
              </div>
            </div>
          </section>


          <section className='vp-section'>
            <div className='row align-items-center'>
              <div className='col-sm-5'>
                <div className='align-items-center inner-container gap-5'>
                  <div className=' justify'>
                    <p className='montser-sml-LightItalic  common-textshadow text-start lh-lg ft-3vh'>
                    Click 'Meet Us' to explore how Learning Measurement Lab's Real-Time Feedback is revolutionizing the field of learning assessment with advanced, real-time insights.</p>                    
                  </div>
                </div>
              </div>
              <div className='col-sm-6'>
                  <div className='w-100 maxw-450 ms-auto d-flex mt-5'>
                    <img src='/images/learning image 1.webp' alt="image not found" className="w-100 object-fit-cover img-fluid rounded-top rounded-0 common-boxshadow img-contain section-img ms-auto" />
                  </div>
              </div>
              <div className="row ">
                    <div className='col-sm-5'>
                      < div className='d-flex align-items-center mt-4 mt-sm-0 justify-content-center footer'>
                          <MyPopupbutton/>
                        </div>
                    </div>
                    <div className='col-sm-7'></div>
                  </div>
            </div>
          </section>
        </div>
        <Footer />
      </div>
      {/* </> */}
    </div>
    // </div>
    // </>


  );
};

export default Culture;

