// Students.js
import React from 'react';
import { useEffect } from 'react';
import '../global.css';
import '../Fonts/fontello-icon.css';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import MyPopupbutton from '../react-calendly/MyPopupbutton';

const Braintraining = () => {
  useEffect(() => {
    const elements = document.getElementsByClassName("text-yellow");

    for (let i = 0; i < elements.length; i++) {
      elements[i].classList.add(".text-dark1");
    }
    const section = document.getElementsByClassName("mainSectionDiv")[0];
    section.classList.add("braintrainingSectionActive");
  }, []);

  return (
    // <>

    <div className=''>
      {/* <div style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'bg-dark' }} className='bg-body'> */}
      {/* <> */}
      <Navbar />
      <div style={{ height: '100%', paddingTop: '100px' }} className='bg-body-brain'>
      <div className="container-fluid vp-container gap-3 gap-md-5 no-flex">
          <section>
            <h4 className='mb-3 custom-large-heading section-margin'>Revolutionary Assessment</h4>
            <hr className='mt-5'></hr>
          </section>
          
          <section className='vp-section'>
              <div className='section-contaner'>

                <div className='row'>
                  <div className='col-12 col-md-6 flex-column align-items-start inner-container gap-5 '>

                    <div className='mb-0 w-100'>
                      <div className='d-flex flex-row align-items-center justify-content-between gap-3'>
                        <div className='job-width lh-lg '>
                          <h5 className='ommon-textshadow  montser-mdm-2 ft-4vh '>FUNCTION:</h5>
                          <span className='montser-mdm-2 common-textshadow ft-2-5vh' >Seamless Integration into Learning </span>
                          <p className='montser-sml-LightItalic common-textshadow ft-2-3vh'>Continuous Assessment blends effortlessly into the educational process, offering a non-intrusive, ongoing evaluation method. This approach eliminates the need for traditional, interruptive testing periods, making the assessment an integral part of the learning journey.</p>
                        </div>
                        <div><i className='demo-icon icon-checkiconsolid' /></div>
                      </div>
                    </div>

                    <div className='mb-0 mb-0 w-100'>
                      <div className='d-flex flex-row align-items-center justify-content-between gap-3'>
                        <div className='job-width lh-lg'>
                          <h6 className='ommon-textshadow  montser-mdm-2 ft-4vh '>FUNCTION:</h6>
                          <span className='montser-mdm-2 common-textshadow ft-2-5vh'>Dynamic Learning Insights</span>
                          <p className='montser-sml-LightItalic common-textshadow ft-2-3vh'>This feature provides educators and organizations with immediate insights into student progress and understanding, enabling timely interventions and personalized support. It shifts the focus from periodic to constant monitoring, enhancing the responsiveness of educational strategies.</p>
                        </div>
                        <div><i className='demo-icon icon-checkiconsolid' /></div>
                      </div>
                    </div>

                    <div className='mb-0 mb-0 w-100'>
                      <div className='d-flex flex-row align-items-center justify-content-between gap-3'>
                        <div className='job-width lh-lg'>
                          <h5 className='ommon-textshadow  montser-mdm-2 ft-4vh '>FUNCTION:</h5>
                          <span className='montser-mdm-2 common-textshadow ft-2-5vh'>Holistic Performance Tracking</span>
                          <p className='montser-sml-LightItalic common-textshadow ft-2-3vh'>Continuous Assessment captures a comprehensive view of a learner's development over time, not just snapshots. This continuous data stream offers a deeper understanding of learning trends, allowing for more accurate and individualized educational planning.</p>
                        </div>
                        <div><i className='demo-icon icon-checkiconsolid' /></div>
                      </div>
                    </div>            
                  </div>
                  <div className='col-md-5 col-12 mt-3 mt-md-0 d-flex align-items-center'>
                    <div className='w-100 maxw-450 ms-auto d-flex mt-5 image-container'>
                      <img src='/images/Brain Training 2.png' alt="image not found" className="w-100 object-fit-cover img-fluid rounded-top rounded-0 common-boxshadow img-contain section-img ms-auto" />
                    </div>
                  </div>
                </div>
              </div>
            </section>
          
          </div>


          <section className='vp-section'>
            <div className='justify-content-center'>
              <div className=' d-flex flex-column align-items-start text-left gap-5'>
                <p className='montser-mdm-2  vp-container common-textshadow mb-3 text-center lh-lg ft-4vh'>
                Transform Your Assessment Strategy with Continuous Assessment
                </p>
                <div className="width-100 ">
                  <img src='/images/Revolutionary Assessments Wide.png' alt="image not found" className="rounded-top rounded-0 common-boxshadow student-wide-img" />
                </div>
              </div>
            </div>
          </section>

          
          <div className="container-fluid vp-container gap-3 gap-md-5 no-flex">

          <section className='vp-section'>
            <div className='row align-items-center'>
            <div className='col-sm-5 d-block d-sm-none'>
                <div className='align-items-center inner-container gap-5'>
                <div className="mt-3 mt-sm-0"><p className="montser-sml-LightItalic lh-lg ft-3vh">
                Delivers a seamless evaluation experience, embedding assessment naturally within the learning process.</p>
                  </div>
                </div>
              </div>
            <div className='col-sm-7'>
                  <div className='w-100 maxw-450 d-flex  me-auto'>
                    <img src='/images/learning image 1.webp' alt="image not found" className="w-100 object-fit-cover img-fluid rounded-top rounded-0 common-boxshadow img-contain section-img ms-auto" />

                  </div>
              </div>
              <div className='col-sm-5 d-none d-sm-block'>
                <div className='align-items-center inner-container gap-5'>
                <div className="mt-3 mt-sm-0">
                  <p className="montser-sml-LightItalic lh-lg ft-3vh">
                  Delivers a seamless evaluation experience, embedding assessment naturally within the learning process.</p>
                    </div>
                </div>
              </div>
            </div>
          </section>
            
          <section className='vp-section'>
            <div className='row align-items-center'>
              <div className='col-sm-5'>
                <div className='align-items-center inner-container gap-5'>
                  <div className=' justify'>
                    <p className='montser-sml-LightItalic  common-textshadow text-start lh-lg ft-3vh'>
                    Offers instant insights into student performance, enabling immediate and effective educational adjustments.                   </p>                    
                  </div>
                </div>
              </div>
              <div className='col-sm-6'>
                  <div className='w-100 maxw-450 d-flex ms-auto'>
                    <img src='/images/Brain Training 1.png' alt="image not found" className="w-100 object-fit-cover img-fluid rounded-top rounded-0 common-boxshadow img-contain section-img ms-auto" />
                  </div>
              </div>
            </div>
          </section>


          <section className='vp-section'>
            <div className='row align-items-center'>
            <div className='col-sm-5 d-block d-sm-none'>
                <div className='align-items-center inner-container gap-5'>
                <div className="mt-3 mt-sm-0"><p className="montser-sml-LightItalic lh-lg ft-3vh">
                Tracks learner progress continuously, providing a detailed picture of their development journey.</p>
                  </div>
                </div>
              </div>
            <div className='col-sm-7'>
                  <div className='w-100 maxw-450 d-flex  me-auto'>
                    <img src='/images/learning image 3.webp' alt="image not found" className="w-100 object-fit-cover img-fluid rounded-top rounded-0 common-boxshadow img-contain section-img ms-auto" />

                  </div>
              </div>
              <div className='col-sm-5 d-none d-sm-block'>
                <div className='align-items-center inner-container gap-5'>
                <div className="mt-3 mt-sm-0">
                  <p className="montser-sml-LightItalic lh-lg ft-3vh">
                  Tracks learner progress continuously, providing a detailed picture of their development journey.</p>
                    </div>
                </div>
              </div>
            </div>
          </section>


          
          <section className='vp-section'>
            <div className='row align-items-center'>
              <div className='col-sm-5'>
                <div className='align-items-center inner-container gap-5'>
                  <div className=' justify'>

                    <p className='montser-sml-LightItalic  common-textshadow text-start lh-lg ft-3vh'>
                    Enhances educational outcomes by allowing for dynamic, personalized learning paths.
                      </p>
                  </div>
                </div>
              </div>
              <div className='col-sm-6'>
                  <div className='w-100 maxw-450 d-flex  ms-auto'>
                    <img src='/images/learning image 5.webp' alt="image not found" className="w-100 object-fit-cover img-fluid rounded-top rounded-0 common-boxshadow img-contain section-img ms-auto" />
                  </div>
              </div>
            </div>
          </section>


          <section className='vp-section'>
            <div className='row align-items-center'>
            <div className='col-sm-5 d-block d-sm-none'>
                <div className='align-items-center inner-container gap-5'>
                <div className="mt-3 mt-sm-0"><p className="montser-sml-LightItalic lh-lg ft-3vh">
                Empowers educators with data to make informed decisions, fostering a more supportive learning environment.</p>
                  </div>
                </div>
              </div>
            <div className='col-sm-7'>
                  <div className='w-100 maxw-450 d-flex  me-auto'>
                    <img src='/images/learning image 2.webp' alt="image not found" className="w-100 object-fit-cover img-fluid rounded-top rounded-0 common-boxshadow img-contain section-img ms-auto" />

                  </div>
              </div>
              <div className='col-sm-5 d-none d-sm-block'>
                <div className='align-items-center inner-container gap-5'>
                <div className="mt-3 mt-sm-0">
                  <p className="montser-sml-LightItalic lh-lg ft-3vh">
                  Empowers educators with data to make informed decisions, fostering a more supportive learning environment.</p>
                    </div>
                </div>
              </div>
            </div>
          </section>


          
          <section className='vp-section'>
            <div className='row align-items-center'>
              <div className='col-sm-5'>
                <div className='align-items-center inner-container gap-5'>
                  <div className=' justify'>

                    <p className='montser-sml-LightItalic  common-textshadow text-start lh-lg ft-3vh'>
                    Click 'Meet Us' to explore how Continuous Assessment can revolutionize your approach to evaluation and unlock the full potential of your learners.
                    </p>
                  </div>
                </div>
              </div>
              <div className='col-sm-6'>
                  <div className='w-100 maxw-450 d-flex  ms-auto'>
                    <img src='/images/Brain Training 3.png' alt="image not found" className="w-100 object-fit-cover img-fluid rounded-top rounded-0 common-boxshadow img-contain section-img ms-auto" />
                  </div>
              </div>
              <div className="row ">
                    <div className='col-sm-5'>
                      < div className='d-flex align-items-center mt-4 mt-sm-0 justify-content-center footer'>
                         <MyPopupbutton/>
                        </div>
                    </div>
                    <div className='col-sm-7'></div>
                  </div>
            </div>
          </section>



        </div>
        <Footer />
      </div>
      {/* </> */}
    </div>
    // </div>
    // </>


  );
};

export default Braintraining;

