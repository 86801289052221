import React, { useState, useEffect } from 'react';
import '../global.css';
import '../Fonts/fontello-icon.css';

const Navbar = () => {
  const [scrolling, setScrolling] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isDropdownOpen1, setIsDropdownOpen1] = useState(false);
  const [isNavbarCollapsed, setIsNavbarCollapsed] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      setScrolling(window.scrollY > 0);
    };

    const handleDocumentClick = (e) => {
      if (!isNavbarCollapsed && !e.target.closest('.navbar')) {
        setIsNavbarCollapsed(true);
      }
    };

    document.addEventListener('click', handleDocumentClick);
    window.addEventListener('scroll', handleScroll);
    
    return () => {
      document.removeEventListener('click', handleDocumentClick);
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isNavbarCollapsed]);

  const handleNavbarToggle = () => {
    setIsNavbarCollapsed(!isNavbarCollapsed);
  };

  const dropdownMenuClassName = `dropdown-menu border-0 ${scrolling ? 'bg-dark' : 'bg-1c2933-90opa'}`;

  const handleDropdownToggle = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleDropdownToggle1 = () => {
    setIsDropdownOpen1(!isDropdownOpen1);
  };

  const baseUrl = "https://platform."

  const handleJoinClick = () => {
    window.open("https://learningmeasurementlab.virtuprep.com", '_blank');
  };

  const handleLoginClick = () => {
    window.open("https://learningmeasurementlab.virtuprep.com", '_blank');
  };

  return (
    <nav className={`navbar navbar-expand-lg position-fixed   ht-10vh ${scrolling ? 'bg-dark' : ''}`}>
      <div className="container-fluid vp-container gap-3 gap-md-5 no-flex">
        <div className='logo-container d-inline-flex align-items-center gap-0 maxw-265 w-100 me-xl-5 me-0'>
              <div className="siteLogo">
              <a href="#">
                <i className="demo-icon icon-site-logo- rotateIcon"></i>
              </a>
              </div>
              <div>
                  <a className="navbar-brand text-virtu-header common-textshadow montser-lrg m-0" href="/">
                     <span>Learning Measurement Lab</span>
                  </a>
              </div>
            
          </div>
        <button
          className="navbar-toggler border-0 text-white"
          type="button"
          aria-controls="navbarSupportedContent"
          aria-expanded={!isNavbarCollapsed}
          aria-label="Toggle navigation"
          onClick={handleNavbarToggle}
        >
          
          <span className={`${scrolling ? 'text-white' : ''}`}>
            {scrolling ? <span className="text-virtu-header f-ui-1">Menu</span> : <span className="text-virtu-header f-ui-1">Menu</span>}
          </span>
        </button>

        <div className={`collapse navbar-collapse mobile-menu mobile-bg-color ${!isNavbarCollapsed ? 'show' : ''}`} id="navbarSupportedContent">
          <ul className="navbar-nav d-flex align-items-start align-items-lg-center center me-auto mb-2 mb-lg-0 header-txt-margin gap-4">
            <li className="nav-item">
                  <a className="d-flex align-items-center navbar-brand text-virtu-header common-textshadow montser-lrg m-0" href="/administrators">
                    <span className='text-decorator-underline'>Administrators</span>
                  </a>
                </li>
            <li className="nav-item">
              
                  <a className="d-flex align-items-center navbar-brand text-virtu-header common-textshadow montser-lrg m-0" href="/corporations">
                  <span className='text-decorator-underline'>Corporations</span>
                  </a>
            </li>
            <li className="nav-item">
                  <a className="d-flex align-items-center navbar-brand text-virtu-header common-textshadow montser-lrg m-0" href="/teachers">
                  <span className='text-decorator-underline'>Teachers</span>
                  </a>
                </li>
            <li className="nav-item dropdown">
              <div
                className="d-flex align-items-center gap-2 text-yellow montser-mdm-2 common-textshadow"
                href="/"
                id="navbarDropdown1"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                onClick={handleDropdownToggle1}>
                  <span className='navbar-brand text-virtu-header common-textshadow montser-lrg m-0 nav-item-span nav-context-menu-title'>Features</span>
               <div className={`${isDropdownOpen1 ? 'rotate-upwards d-flex align-center':'d-flex align-center'}`}>
                <i  className='demo-icon icon-opened-folder dropdown-icon-size'></i>
                </div>
              </div>
              <ul className={dropdownMenuClassName} aria-labelledby="navbarDropdown1">
                <li>
                  <a className="dropdown-item-effect text-yellow montser-lrg common-textshadow" href="/features/adaptivemeasurement">
                    <span className='text-decorator-underline'>Adaptive Measurement</span>
                  </a>
                </li>
                <li>
                  <a className="dropdown-item-effect text-yellow montser-lrg common-textshadow" href="/features/insightfulanalytics">
                  <span className='text-decorator-underline'>Insightful Analytics</span>
                  </a>
                </li>
                <li>
                  <a className="dropdown-item-effect text-yellow montser-lrg common-textshadow" href="/features/predictiveanalytics">
                  <span className='text-decorator-underline'>Predictive Analytics</span>
                  </a>
                </li>
                <li>
                  <a className="dropdown-item-effect text-yellow montser-lrg common-textshadow" href="/features/real-timefeedback">
                    <span className='text-decorator-underline'>Real-Time Feedback</span>
                  </a>
                </li>
                <li>
                  <a className="dropdown-item-effect text-yellow montser-lrg common-textshadow" href="/features/revolutionaryassessment">
                  <span className='text-decorator-underline'>Revolutionary Assessment</span>
                  </a>
                </li>
                <li>
                  <a className="dropdown-item-effect text-yellow montser-lrg common-textshadow" href="/features/strategiccollaboration">
                  <span className='text-decorator-underline'>Strategic Collaboration</span>
                  </a>
                </li>
                
              </ul>
            </li>            
            <li className="nav-item">
              <a className="d-flex align-items-center navbar-brand text-virtu-header common-textshadow montser-lrg m-0" href="/blogdashboard">
              <span className='text-decorator-underline'>Blogs</span>
              </a>
            </li>
            <li className="nav-item">
              <a className="d-flex align-items-center navbar-brand text-virtu-header common-textshadow montser-lrg m-0" href="/research">
              <span className='text-decorator-underline'>Research</span>
              </a>
            </li>
          </ul>
          <ul className="navbar-nav d-flex align-center gap-3 flex-row justify-content-end">
            <li className="nav-item px-2">
              <a onClick={handleLoginClick} className="nav-link montser-lrg m-0 text-yellow d-flex align-center justify-content-center gap-2 cursor-pointer" rel="noopener noreferrer">
                <span class="text-decorator-underline">Log in</span>
                <div class="rotated-icon"><i class="demo-icon icon-up-arrow  dropdown-icon-size"></i></div>
              </a>
            </li>

            <li className="nav-item join">
              <button type="button" className="outline-lightgreen py-0 montser-lrg m-0 rounded-top rounded-0 btn btn-outline-light " onClick={handleJoinClick}>
              <span className='text-yellow'>Join</span>
              </button>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;