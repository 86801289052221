// Students.js
import React from 'react';
import { useEffect } from 'react';
import '../global.css';
import '../Fonts/fontello-icon.css';
import './aboutus.css';
import VideoBackground from '../components/VideoBackground';

import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

const Aboutus = () => {
    const dynamicVideoLink = "videos/Sequence 01_2.mp4";
    const pageName = "aboutPage"
    const buttonText = "Home"

    useEffect(() => {
        const elements = document.getElementsByClassName("text-yellow");

        for (let i = 0; i < elements.length; i++) {
            elements[i].classList.add(".text-dark1");
        }
        const section = document.getElementsByClassName("mainSectionDiv")[0];
        section.classList.add("aboutSectionActive");
    }, []);

    return (
        // <>

        <div className=''>
            {/* <div style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'bg-dark' }} className='bg-body'> */}
            {/* <> */}
            <Navbar />
            <VideoBackground
                videoLink={dynamicVideoLink}
                buttonText={buttonText}
                pageName={pageName}
            />
            <div style={{ height: '100%', paddingTop: '20px' }} className='bg-body-about'>
                <div className="container-fluid vp-container gap-3 gap-md-5 no-flex">
                    <div className='content-margin '>
                        <div className='mt-2 pt-3'>
                        <heading className='montser-lrg-bold align-items-center py-4'>Revolutionizing Learning Measurement for Educators and Institutions</heading>

                        <p className='align-items-center monster-lrg-normal p-gap ' style={{ paddingTop: '1.5rem' }}>
                        Welcome to Learning Measurement Labs, where our mission is to transform the landscape of educational assessment for teachers, principals, governments, colleges, and corporations. We are pioneering the next generation of learning measurements, designed to provide a comprehensive, real-time understanding of student and employee capabilities. By moving beyond traditional, sporadic assessments, we offer a continuous, in-depth analysis that captures the essence of learning and skill development over time.</p>
                       
           
                        <heading className='montser-lrg-bold align-items-center py-4'>Our Vision: Elevating Standards for Educational and Professional Success</heading>
                        <p className='align-items-center monster-lrg-normal py-4'>
                        At Learning Measurement Labs, we are dedicated to redefining the standards of success for learners across all stages of education and professional development. Our innovative approach ensures that assessments become a seamless, integrated part of the learning process, offering ongoing insights and a dynamic perspective on each individual's growth and potential. This shift towards continuous measurement is crucial for educators, institutions, and organizations that rely on precise, actionable data to make informed decisions about curriculum, admissions, qualifications, and professional advancement.
                        </p>

                        <heading className='montser-lrg-bold align-items-center py-4'>The Learning Measurement Labs Advantage: Precision, Insight, Impact</heading>
                        <p className='align-items-center monster-lrg-normal pt-1-5rem'>
                        Continuous, Comprehensive Assessment: Our cutting-edge technology enables the continuous collection of data, ensuring that learning and assessment are intertwined. This approach provides a richer, more accurate reflection of a learner's abilities and progress over time.</p>
                        <p className='align-items-center monster-lrg-normal pt-1-5rem'>
                        Advanced Trend and Trajectory Analysis: By analyzing learning patterns and growth trajectories, we deliver unparalleled insights into individual and group performance. This information is invaluable for educators and decision-makers, offering a deeper understanding of how to enhance learning outcomes and adapt strategies for success.</p>
                        <p className='align-items-center monster-lrg-normal py-4'>
                        Efficient, Time-Saving Solutions: Our integrated assessment model streamlines the evaluation process, saving precious time that can be better spent on teaching, learning, and development. This efficiency is a game-changer for educational institutions and corporations alike, maximizing the impact of instructional time and training programs.</p>
                        <p className='align-items-center monster-lrg-normal p-gap'>
                        Data-Driven Decision Making: The extensive data collected through our assessments empowers educators, administrators, and corporate leaders to make informed decisions. Tailored instruction, admissions policies, qualification requirements, and professional development initiatives can all be enhanced based on our precise, real-time feedback.</p>
                       
                        <heading className='montser-lrg-bold align-items-center py-4'>Empowering Stakeholders Across the Educational and Professional Spectrum</heading>
                        <p className='align-items-center monster-lrg-normal py-4'>
                        Learning Measurement Labs is committed to empowering educators, institutions, and organizations with the tools and insights needed to foster excellence in learning and professional development. Our work supports a broad spectrum of stakeholders, from teachers seeking to understand their students' needs better, to colleges assessing applicants' readiness, and corporations evaluating employee qualifications.</p>
                        <p className='align-items-center monster-lrg-normal p-gap '>
                        We invite you to join us in this transformative journey, where every aspect of learning and professional development is measured with precision, insight, and care. Together, we can set new standards for success and open up a world of possibilities for learners and professionals everywhere.</p>

                        <heading className='montser-lrg-bold align-items-center py-4'>Welcome to the future of learning measurement. Welcome to Learning Measurement Labs.</heading>
                        </div>

                    </div>
                </div>
                <Footer />
            </div>
        </div>
    );
};

export default Aboutus;

