// Footer.js
import React from 'react';
import '../Fonts/fontello-icon.css';
import  { useState, useEffect } from 'react';
import '../global.css';
const Footer = () => {
    const [scrolling, setScrolling] = useState(false);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setScrolling(window.scrollY > 0);
    };
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  
  const scrollToTop = () => {
    window.scrollTo({
      speed:0,
      top: 0,
      speed:5,
      behavior: 'smooth', // Use smooth scrolling
    });
  };

  const handleDropdownToggle = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };
  const dropdownMenuClassName = `dropdown-menu border-0 ${scrolling ? 'bg-dark-footer' : 'bg-transparent'}`;

  return (
    <footer className="text-white bg-dark-footer footer-padding">
    <div className="footer-container vp-container d-flex gap-5 flex-column"> 
        <hr class="m-0" />
        <div className="d-flex flex-column flex-lg-row justify-content-start align-items-lg-center gap-2 gap-lg-5">
        <div className='logo-container d-inline-flex align-items-center gap-2 maxw-265 w-100 me-xl-5 me-0'>
              <h1 className="siteLogo">
              <a href="#">
                <i className="demo-icon icon-site-logo- rotateIcon"></i>
              </a>
              </h1>
              <a className="navbar-brand bg-dark-footer common-textshadow montser-lrg m-0" href="/">
                <span>Learning Measurement Lab</span>
              </a>
          </div>
          <ul className="navbar-nav flex-wrap d-flex flex-row align-items-start align-items-md-center me-auto mb-2 mb-lg-0 gap-sm-4 gap-2 footer-menu w-100">
          <li className="nav-item">
             <a className="dropdown-item-effect  montser-mdm-2  montser-mdm-2 bg-dark-footer" href="/administrators">
               Administrators
             </a>
           </li>
           <li className="nav-item">
              <a className="nav-link bg-dark-footer common-textshadow montser-mdm-2 " href="/corporations">
                <span className="text-decorator-underline">Corporations</span>
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link bg-dark-footer common-textshadow montser-mdm-2 " href="/teachers">
              <span className="text-decorator-underline">Teachers</span>
              </a>
            </li>
         
           
            <li className="nav-item">
              <a className="nav-link bg-dark-footer common-textshadow montser-mdm-2 " href="/blogdashboard">
                <span className="text-decorator-underline">Blogs</span>
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link bg-dark-footer common-textshadow montser-mdm-2 " href="/research">
                <span className="text-decorator-underline">Research</span>
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link bg-dark-footer common-textshadow montser-mdm-2 " href="/about-us">
                <span className="text-decorator-underline">About us</span> 
              </a>
            </li>
          </ul>  
        </div>
        <div className='d-flex flex-column flex-md-row align-items-start gap-3 gap-md-5 align-items-md-center mt-4 mt-md-0'>
        
        <div className="nav-item minw-265 w-100 me-xl-4 ms-xl-4 me-0">
            <a className="nav-link bg-dark-footer montser-mdm-2 common-textshadow" to="/">
              <span>Virtu Prep © 2015-2024</span> 
            </a>
        </div>  
        <ul className="navbar-nav d-flex align-center me-auto mb-2 mb-lg-0 flex-row gap-4">
            <li className="nav-item">
              <a className="nav-link bg-dark-footer copy-right-text text-decorator-underline montser-mdm-2 common-textshadow" href="https://www.facebook.com/virtuprep">
              <span className='text-decorator-underline'>Facebook</span>  
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link bg-dark-footer montser-mdm-2 common-textshadow" href=" https://www.linkedin.com/company/virtuprep">
              <span className='text-decorator-underline'>LinkedIn</span>
              </a>
            </li>
          </ul>
          <ul class="navbar-nav d-flex align-center gap-3 flex-row">
            <li class="nav-item back-to-top">
              <a href="#" class="nav-link copy-right-text-top bg-dark-footer montser-mdm-2 common-textshadow pe-auto" onClick={scrollToTop}>
                <span class="text-decorator-underline">Back to top</span>
                <i class="demo-icon icon-up-arrow  m-1 dropdown-icon-size"></i>
              </a>
            </li>
          </ul>
          </div>
      </div>
    </footer>
  );
};

export default Footer;